
.assignee {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &-row {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .assignee-item {
      .client__details {
        &_info {
          &_card {
            margin: 0 auto;
          }
        }
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    //padding: 1rem 1.25rem;
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: var(--gray-400);
    margin-bottom: 2rem;

    &__tooltip {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &__title {
      text-transform: uppercase;
      margin: 0;
    }
  }

  .row {
    margin: 0 auto;
    width: 100%;
  }

  &-item {
    .form-control:disabled {
      background-color: #fff;
    }

    .client__details {
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 1.5rem;
        line-height: 28px;
        color: var(--gray-400);
        margin: 2rem 0 1rem 0;
        width: 100%;
        max-width: 40rem;
      }

      &_info {
        &_card {
          display: flex;
          align-items: center;
          width: 100%;
          //max-width: 40rem;
          height: 56px;
          border: 2px solid #e5e7eb;
          border-radius: 32px;
          padding: 0 20px;
          position: relative;
          margin: 0.5rem auto;

          & svg {
            position: absolute;
            right: 20px;
            //z-index: 100;
          }

          & label {
            text-transform: uppercase;
            margin: 0;
            padding-right: 10px;
            color: var(--gray-400);
            white-space: nowrap;
            font-size: 0.8rem;
            letter-spacing: 1px;
          }

          input {
            font-family: CraftworkSans, serif;
            height: 100%;
            width: 100%;
            border: none;
            text-align: right;
            padding-right: 4px;
            font-size: 18px;
            line-height: 22px;
            font-weight: 900;
            color: var(--gray-600);
          }
        }
      }
    }
  }
}

.agree {
  margin-top: 32px;
  margin-bottom: 48px;

  &-row {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.download-button {
  cursor: pointer;
  width: 100%;
}
