
.input-label {
  font-family: CraftworkSans, serif;
  position: absolute;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray-400);
  width: 100%;
  display: flex;
  padding-left: 1rem;
  margin-bottom: 10px;
  z-index: 3;

  span {
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    position: absolute;
    top: 12px;
    left: 24px;
  }
}

.base-calendar {
  position: relative;

  .date-picker {
    width: 100%;

    &.error {
      ::v-deep .mx-input-wrapper .mx-input {
        border: 0.25rem solid var(--red-500) !important;
      }
    }

    &.label {
      ::v-deep .mx-input-wrapper .mx-input {
        padding-top: 23px !important;
      }
    }

    ::v-deep .mx-input-wrapper {
      .mx-input {
        font-family: Inter, sans-serif !important;
        box-shadow: none;
        border: 0.25rem solid transparent;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 2rem;
        padding: 17px 20px;
        height: 56px;
        background-color: var(--gray-100);

        &:hover {
          background-color: var(--gray-200);
        }

        &:focus-within {
          font-family: Inter, sans-serif;
          border: 0.25rem solid var(--gray-200);
          background-color: var(--gray-100);
        }

        &::placeholder {
          background-color: transparent;
          color: var(--gray-400);
          font-family: Inter, sans-serif;
        }
      }

      .mx-icon-calendar,
      .mx-icon-clear {
        cursor: pointer;
        font-size: 24px;
        right: 1.25rem;
        //display: none;
      }
    }
  }

  .calendar-icon {
    ////position: relative;
    //position: absolute;
    //right: 1.25rem;
    //top: 50%;
    //transform: translateY(-50%);
  }
}
