
.pdf-page {
  position: relative;
  padding: 32px;
  background-color: #fff;
  display: flex;
  height: 1100px;
  flex-direction: column;
  justify-content: space-between;

  .page-count {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--gray-500);
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50);
    display: flex;
    justify-content: center;
  }

  .pdf-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    &__title {
      h5 {
        font-family: CraftworkSans, serif;
        font-weight: 900;
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 6px;
        color: var(--violet-700);
      }

      p {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        //font-size: 8px;
        //line-height: 10px;
        color: var(--gray-500);
        margin-bottom: 0;

        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }

    &__logo {
      width: 90px;
      height: 48px;

      img {
        max-width: 100%;
      }
    }
  }

  .pdf-body {
    font-family: Inter, sans-serif;

    .pdf-img {
      display: flex;
      justify-content: center;
      align-items: center;
      //height: 100%;
      height: 350px;
      margin-bottom: 36px;

      img {
        //width: 100%;
        max-height: 100%;
        height: 100%;
      }
    }

    .pdf-features {
      margin-bottom: 2rem;

      .pdf-feature {
        &__title {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          //font-size: 8px;
          //line-height: 10px;
          color: var(--gray-500);
          margin-bottom: 2px;
        }

        &__content {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          img {
            width: 100%;
            max-width: 16px;
            margin-right: 6px;
          }

          p {
            font-weight: 600;
            //font-size: 12px;
            //line-height: 16px;
            font-size: 16px;
            line-height: 24px;
            color: var(--gray-700);
            margin-bottom: 0;
          }
        }
      }
    }

    .pdf-payment {
      &__type {
        font-weight: 600;
        //font-size: 12px;
        //line-height: 15px;
        font-size: 17px;
        line-height: 20px;
        color: var(--gray-700);
      }

      &__title {
        font-family: CraftworkSans, serif;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        //font-size: 12px;
        //line-height: 14px;
        color: var(--gray-400);
        margin-bottom: 16px;
      }

      &__block {
        position: relative;
        display: flex;
        margin-bottom: 8px;

        &:after {
          position: absolute;
          content: "";
          bottom: 0;
          right: 15px;
          height: 1px;
          width: calc(100% - 30px);
          border-bottom: 1px dashed var(--gray-200);
        }
      }

      &__items {
        display: flex;
        flex-direction: column;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .option {
          color: var(--gray-500);
        }

        .value {
          color: var(--gray-700);
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          //font-size: 8px;
          //line-height: 10px;
          margin-bottom: 0;
        }
      }

      &__last {
        justify-content: flex-end;

        .pdf-payment__item {
          p {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 15px;
            //font-size: 8px;
            //line-height: 10px;
            margin-bottom: 0;
          }

          .option {
            color: var(--gray-500);
            font-size: 9px;
            line-height: 12px;
          }

          .value {
            font-size: 11px;
            line-height: 15px;
            color: var(--gray-700);
          }
        }
      }

      &__subtotal {
        .option {
          color: var(--teal-500);
        }

        .value {
          color: var(--teal-700);
        }
      }

      &__total {
        p.option {
          font-size: 12px !important;
          line-height: 16px !important;
          color: var(--violet-500) !important;
        }

        .value {
          font-size: 15px !important;
          line-height: 20px !important;
          //font-size: 10px;
          //line-height: 12px;
          color: var(--violet-700) !important;
        }
      }
    }
  }

  .pdf-footer {
    border-top: 1px solid var(--gray-200);
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 24px;

    &__title {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;
    }

    &__contact {
      flex-direction: column;
      display: flex;
      row-gap: 6px;
    }

    &__link {
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      column-gap: 4px;
      color: var(--gray-500);
      margin-bottom: 0;

      img {
        width: 100%;
        max-width: 12px;
      }
    }

    &__logo {
      width: 250px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid var(--gray-100);
      background-color: var(--gray-50);
      border-radius: 1rem;
      padding: 1rem;
      row-gap: 8px;

      img {
        width: 100%;
        max-width: 110px;
      }

      p {
        font-family: CraftworkSans, serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }
}

::v-deep .pdf-table {
  font-family: Inter, sans-serif;

  &__head > tr > th {
    background-color: var(--gray-100) !important;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    //font-size: 8px;
    //line-height: 10px;
    padding: 8px;
  }

  &__body > tr > td {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    //font-size: 8px;
    //line-height: 10px;
    padding: 8px;
  }

  .table-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__teal {
      color: var(--teal-600) !important;
    }
  }

  tr > td,
  th {
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    //font-size: 8px !important;
    //line-height: 10px !important;

    div {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      //font-size: 8px;
      //line-height: 10px;
    }
  }

  tbody tr > td {
    color: var(--gray-600);
  }
}
