
.ch-calculator-wrapper {
  display: grid;
  grid-template-areas:
    "cv-cell-1 cv-cell-1"
    "cv-cell-2 cv-cell-2"
    "cv-cell-3 cv-cell-4"
    "cv-cell-5 cv-cell-6"
    "cv-cell-7 cv-cell-7"
    "cv-cell-8 cv-cell-8";
  gap: 1rem;
  height: fit-content;

  &.ch-another-price-content {
    grid-template-areas:
      "cv-cell-1 cv-cell-1"
      "cv-cell-2 cv-cell-2"
      "cv-cell-3 cv-cell-4"
      "cv-st-price cv-price-m2"
      "cv-cell-5 cv-cell-6"
      "cv-cell-7 cv-cell-7"
      "cv-cell-8 cv-cell-8";

    //"cv-cell-1 cv-cell-1"
    //"cv-cell-2 cv-cell-2"
    //"cv-cell-3 cv-cell-4"
    //"cv-cell-5 cv-cell-6"
    //"cv-cell-7 cv-cell-7"
    //"cv-cell-8 cv-cell-8";
  }

  //&.ch-when-full-payment {
  //  grid-template-areas:
  //    "cv-cell-1 cv-cell-1"
  //    "cv-cell-3 cv-cell-4"
  //    "cv-cell-5 cv-cell-6"
  //    "cv-cell-7 cv-cell-7"
  //    "cv-cell-8 cv-cell-8";
  //}

  &.ch-when-no-initial {
    grid-template-areas:
      "cv-cell-1 cv-cell-1"
      "cv-cell-2 cv-cell-2"
      "cv-cell-3 cv-cell-3"
      "cv-cell-5 cv-cell-6"
      "cv-cell-7 cv-cell-7"
      "cv-cell-8 cv-cell-8";
  }

  .cw-starting-price {
    grid-area: cv-st-price;
  }

  .cw-price-m2 {
    grid-area: cv-price-m2;
  }

  .cw-payment-option {
    grid-area: cv-cell-1;
  }

  .cw-monthly-payment {
    grid-area: cv-cell-2;
  }

  .cw-prepayment {
    grid-area: cv-cell-3;
  }

  .cw-initial-fee {
    grid-area: cv-cell-4;
  }

  .cw-total-discount {
    grid-area: cv-cell-5;
  }

  .cw-discount-per-m2 {
    grid-area: cv-cell-6;
  }

  .cw-first-payment-date {
    grid-area: cv-cell-7;
  }

  .cw-monthly-payment-date {
    grid-area: cv-cell-8;
  }
}
