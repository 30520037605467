
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 1rem;
  margin-bottom: 2rem;

  .header-left {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    column-gap: 0.5rem;
  }

  &-title {
    font-family: CraftworkSans, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    color: var(--gray-600);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
