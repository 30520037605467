
@import "../../../assets/scss/utils/pagination";

* {
  font-family: Inter, serif;
  font-style: normal;
  color: var(--gray-600);
  font-weight: 600;
}

.cards {
  display: flex;
  gap: 24px;

  .currency__chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //width: 306px;
    //height: 160px;
    max-width: 32rem;
    flex-grow: 1;
    border-radius: 32px;
    margin-top: 32px;
    margin-bottom: 2rem;
    background-color: var(--gray-50);
    border: 2px solid var(--gray-200);
    padding: 1.5rem;
  }
}

/* PAYMENT HISTORY */
.payments__history {
  //border-top: 6px solid var(--gray-100);
  border-bottom: 6px solid var(--gray-100);
  padding-top: 2rem;
  padding-bottom: 2rem;

  .heading {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: CraftworkSans, serif;
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 28px;
      color: var(--gray-600);
    }

    .total-payments {
      font-family: CraftworkSans, serif;
      font-weight: 600;
      font-size: 18px;
      color: var(--violet-500);
    }

    .import__button {
      //background-color: var(--gray-100);
      margin-right: 1rem;
    }
  }

  ::v-deep .table__list {
    max-height: none;

    table {
      color: var(--gray-600);

      thead tr th {
        font-family: CraftworkSans, serif;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        color: var(--gray-400) !important;
        padding: 1.25rem 1rem 1.25rem 1rem;
      }

      td {
        vertical-align: middle;
      }
    }

    .table.b-table[aria-busy="true"] {
      opacity: 1 !important;
    }

    .empty__scope {
      font-size: 1.5rem;
      min-height: 5rem;
    }
  }

  ::v-deep .row__head__bottom-border {
    border-bottom: 2px solid var(--gray-200) !important;
  }

  ::v-deep .row__body__bottom-border:not(:last-child) {
    border-bottom: 2px solid var(--gray-200) !important;
  }

  .actions {
    display: flex;
    align-items: center;

    .icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .edit__icon {
      background-color: var(--violet-600);
    }

    .delete__icon {
      background-color: var(--red-600);
      margin-left: 1rem;
    }
  }
}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--gray-600);
}

.go__back {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.payment-modal {
  .content__form__select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray-100);
    border-radius: 2rem !important;
    border: none;
    color: var(--gray-600);
    position: relative;

    .form__select {
      background-color: transparent;
      border: none;
      color: var(--gray-600);
      margin: 0 1rem;
      width: 100%;
    }

    ::v-deep .x-form-select-main .x-form-select-options {
      max-height: 200px !important;
    }
  }

  input {
    border-radius: 2rem !important;
  }
}

/* PAYMENT SCHEDULE */
.payment__schedule {
  margin-top: 2rem;

  .title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 1rem;
    font-family: CraftworkSans, serif;
    font-weight: 900;
    color: var(--gray-600);
    //margin-left: 1.5rem;
  }

  &-status {
    border-radius: 2rem;
    padding: 0.8rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
  }

  &-paid {
    background-color: var(--green-100);
    color: var(--green-600);
  }

  &-partially {
    background-color: var(--blue-100);
    color: var(--blue-600);
  }

  &-waiting {
    background-color: var(--yellow-100);
    color: var(--yellow-600);
  }

  &-uncertain {
    background-color: var(--gray-100);
    color: var(--gray-600);
  }
}

::v-deep .payment__schedule__thead {
  color: var(--gray-400);
  border-top: 2px solid white;

  th {
    padding: 20px 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 2px solid var(--gray-200);
    font-family: CraftworkSans, serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: var(--gray-400) !important;

    &:nth-child(3) {
      border-right: 2px solid var(--gray-200);
    }
  }
}

::v-deep .payment__schedule__tbody {
  color: var(--gray-600);
  font-size: 16px;
  line-height: 22px;

  tr:nth-last-child(1) {
    border-bottom: 2px solid var(--gray-200);
  }

  td {
    vertical-align: middle !important;
    padding: 20px 16px;
  }

  td:nth-child(3) {
    border-right: 2px solid var(--gray-200);
  }
}

::v-deep .initial__warning {
  .modal-content {
    width: 40rem;
    height: 30rem;
  }
}

.warning__border {
  border: 2px solid var(--red-600);
}

.warning__before__delete {
  &-head {
    display: flex;
    align-items: center;

    .title {
      font-size: 2.25rem;
      line-height: 42px;
      margin-left: 1rem;
    }
  }

  &-main {
    display: block;
    max-width: 60%;
    font-family: Inter, sans-serif;
    color: var(--gray-600);
    margin-left: 0.5rem;
  }

  &-footer {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.payment-addition-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1010px) {
  .cards {
    flex-direction: column;
    gap: 0;

    .currency__chart {
      width: 60%;
    }
  }
}
