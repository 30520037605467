
::v-deep .main__content
    .slider__image
        object-fit: contain

::v-deep .slider-content
    margin: 1rem

    .swiper-container
        display: flex
        align-items: center
        justify-content: center
        height: 24rem

        .swiper-slide
            cursor: grab
            display: flex
            justify-content: center
            align-items: center
            padding: 0 58px

            .swiper-image
                width: 100%
                max-height: 19rem
                object-fit: contain
        //height: 16rem

        .swiper-button
            width: 3rem
            height: 3rem
            border-radius: 50%
            background-color: var(--gray-100)

        .swiper-button-next::after,
        .swiper-button-prev::after
            content: none

    .swiper-pagination
        margin-top: 3rem

        &-bullets
            bottom: 1rem

        &-bullet
            width: 0.75rem
            height: 0.75rem
            margin-right: 0.3rem
            background-color: var(--gray-400)

            &-active
                background-color: var(--violet-400)


::v-deep .calculator-tab

    .bottom__line
        height: 8px
        width: 100%
        position: absolute
        bottom: 0
        left: 0
        border-radius: 1rem 1rem 0 0
        background-color: var(--gray-100)

    .card-header
        padding: 0
        background-color: transparent
        margin: 0
        border-bottom: none

    .tab-content
        position: relative
    //padding-top: 12px

    .card-body
        padding: 0

    .card-header-tabs
        margin: 0


    .nav-tabs
        display: flex
        flex-wrap: nowrap
        column-gap: .5rem
        justify-content: space-around
        position: relative

        .nav-item
            width: 100%

        .nav-link
            display: flex
            justify-content: center
            align-items: center
            border: none
            padding: 0 12px 12px
            color: var(--gray-600)

            &.active
                position: relative
                color: var(--violet-600)

                &:after
                    content: ''
                    height: 8px
                    width: 100%
                    position: absolute
                    bottom: 1px
                    left: 0
                    border-radius: 1rem 1rem 0 0
                    background-color: var(--violet-600)
                    z-index: 5


.price__section
    font-family: CraftworkSans, serif
    font-size: 1.5rem
    font-weight: 900
    margin: 1.5rem 0

    &-amount
        color: var(--gray-600) !important
        line-height: 28px
        text-align: end
        width: 100%

    &-square-amount
        color: var(--gray-500) !important
        font-size: 18px
        line-height: 22px


.button__view
    display: inline-block
    padding: 1rem 1.5rem
    background-color: var(--gray-100)
    border-radius: 2rem
    font-family: Inter, sans-serif


.particular__qualities
    font-family: Inter, sans-serif
    margin-bottom: 2.5rem

    .title
        font-size: 1.25rem


    &-content
        display: flex
        flex-wrap: wrap

        .part
            margin-top: 1rem

            &:not(:last-child)
                margin-right: 1rem

            .image__container
                width: 4rem
                height: 4rem
                display: block
                background-color: var(--violet-300)
                margin-bottom: 0.75rem

            .description
                font-size: 14px
                line-height: 20px
